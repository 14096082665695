import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/d/Projects/node/oasis_website/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/d/Projects/node/oasis_website/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/d/Projects/node/oasis_website/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderComponent"] */ "/mnt/d/Projects/node/oasis_website/src/components/layout/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarComponent"] */ "/mnt/d/Projects/node/oasis_website/src/components/layout/navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/mnt/d/Projects/node/oasis_website/src/components/provider.tsx");
;
import(/* webpackMode: "eager" */ "/mnt/d/Projects/node/oasis_website/src/styles/globals.scss");

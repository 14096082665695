'use client'

import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'

import { type Menu } from '@/constants/menu'
import { Link, locales, usePathname } from '@/libs/intl'
import { useSelector } from '@/store'
import { cls } from '@/utils'

import { TranslateComponent } from './translate'

export function HeaderComponent() {
  // __STATE's
  const menu = useSelector(({ common }) => common.menu)
  const locale = useLocale()
  const currentLocale = locales.find((r) => r.locale === locale)!

  // __RENDER
  return (
    <header className='ui--header'>
      <div className='ui--header-container container'>
        <div className='ui--header-grid'>
          <div className='columns flex justify-end gap-4 xl:gap-6'>
            {menu.slice(0, 5).map((record) => (
              <MenuComponent
                className='dropdown-wrapper'
                data={record}
                lang={currentLocale.short}
                key={record.key}
              />
            ))}
          </div>

          <div className='columns'>
            <Image
              className='mx-auto size-20 object-contain object-center'
              src='/static/images/logo-white-sm.png'
              width={100}
              height={100}
              quality={100}
              priority
              alt='Oasis Spa Origin logo white'
            />
          </div>

          <div className='columns flex justify-start gap-4 xl:gap-6'>
            {menu.slice(5).map((record) => (
              <MenuComponent
                className='dropdown-wrapper'
                data={record}
                lang={currentLocale.short}
                key={record.key}
              />
            ))}

            <div className='grid'>
              <button className='btn h-full px-2' type='button' aria-label='Menu shoping cart.'>
                <span className='icon bi bi-cart3 text-xl text-white/80'></span>
              </button>
            </div>

            <TranslateComponent key={10} />
          </div>
        </div>
      </div>
    </header>
  )
}

export function MenuComponent({ className, data, lang }: { data: Menu; lang: string; className?: string }) {
  // __STATE's
  const t = useTranslations()
  const pathname = usePathname()

  // __RENDER
  return (
    <div className={cls('ui--header-menu', className)}>
      {data.isLegacy ? (
        <a
          className='router-link'
          href={`https://oasisspa.net/${lang}${data.href}?utm_source=New Webapp`}
          target='_parent'
          rel='external'
          aria-disabled={data?.disabled}
          aria-label={t(data.label)}>
          <span className='text'>{t(data.label)}</span>
        </a>
      ) : (
        <Link
          className={cls('router-link', { 'router-link-active': pathname === data.href })}
          href={data.href}
          aria-disabled={data?.disabled}
          aria-label={t(data.label)}>
          <span className='text'>{t(data.label)}</span>
        </Link>
      )}

      {data?.children?.length && (
        <div className='dropdown-ul'>
          <div className='relative min-w-36 rounded-sm bg-white/60 py-2 ring-1 ring-white/70 drop-shadow-2xl backdrop-blur-xl'>
            {data.children.map((record) => (
              <MenuComponent className='dropdown-li' data={record} lang={lang} key={record.key} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

'use client'

import { useLocale } from 'next-intl'
import { useEffectOnce } from 'react-use'

import { APP_BASE_URL } from '@/constants/configs'
import { menu } from '@/constants/menu'
import { useLoader } from '@/hooks'
import axios from '@/services/axios'
import { useDispatch } from '@/store'
import { commonAct } from '@/store/common.store'

export function Bootstrap() {
  // __STATE's
  const dispatch = useDispatch()
  const loader = useLoader()

  const locale = useLocale()

  // __FUNCTION's
  const handleSetCommon = async () => {
    const { data: branches } = await axios.get(`/api-v2/branches`, {
      baseURL: APP_BASE_URL,
      params: { locale }
    })

    const results = menu.map((r) => {
      if (r.key.endsWith('destination') && r.children) {
        return {
          ...r,
          children: r.children
            .filter((r) =>
              branches.some((branch: any) => r.key === `.${branch.city.toLowerCase().replace(' ', '-')}`)
            )
            .map((r) => {
              if (r?.children) {
                return {
                  ...r,
                  children: branches
                    .filter((branch: any) => r.key === `.${branch.city.toLowerCase().replace(' ', '-')}`)
                    .map((branch: any) => ({
                      key: `.${branch.name_short}`,
                      label: branch.name,
                      href: `/destination/${branch.city.toLowerCase()}/${branch.public_path}`,
                      isLegacy: true
                    }))
                }
              }

              return r
            })
        }
      }

      return r
    })

    const action = commonAct.setMenu(results)
    dispatch(action)

    loader.off()
  }

  // __EFFECT's
  useEffectOnce(() => {
    handleSetCommon()
  })

  // __RENDER
  return null
}

'use client'

import { useParams, usePathname, useRouter } from 'next/navigation'
import { useRef, useState } from 'react'
import { useClickAway } from 'react-use'

import { Link, locales } from '@/libs/intl'

export function TranslateComponent() {
  // __STATE's
  const nodeRef = useRef<HTMLDivElement>(null)
  const [state, setState] = useState<boolean>(false)

  const router = useRouter()
  const params = useParams()
  const pathname = usePathname()

  // __FUNCTION's
  const handleClick = (value: string) => {
    location.assign(pathname.replace(`/${params.locale}`, `/${value}`))
  }

  // __EFFECT's
  useClickAway(nodeRef, () => {
    setState(false)
  }, ['click'])

  // __RENDER
  return (
    <div className='ui--header-translate' ref={nodeRef}>
      <button
        className='btn h-full px-2'
        type='button'
        aria-label='Menu translate.'
        onClick={() => setState((prev) => !prev)}>
        <span className='icon bi bi-translate text-xl opacity-80'></span>
      </button>

      {state && (
        <div className='ui--header-translate-dropdown'>
          <ul className='grid gap-1'>
            {locales.map((record, index) => (
              <li className='' key={index}>
                <button
                  className={`btn btn-intl ${record.locale === params.locale && 'active'}`}
                  type='button'
                  onClick={() => handleClick(record.locale)}
                  aria-label='Menu translate.'>
                  <span className='text'>{record.label}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

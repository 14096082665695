'use client'

import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import { useEffect, useState } from 'react'

import { Menu, menu } from '@/constants/menu'
import { Link, locales } from '@/libs/intl'
import { cls, scrollOff } from '@/utils'

export function NavbarComponent() {
  // __STATE's
  const locale = useLocale()
  const currentLocale = locales.find((r) => r.locale === locale)!

  const [active, setActive] = useState<boolean>(false)

  // __EFFECT's
  useEffect(() => {
    scrollOff(active)
  }, [active])

  // __RENDER
  return (
    <nav className='ui--navbar'>
      <div className='ui--navbar-container'>
        <div className='columns'></div>

        <div className='columns'>
          <Image
            className='mx-auto size-12 object-contain object-center'
            src='/static/images/logo-sm.png'
            width={60}
            height={60}
            quality={100}
            priority
            alt='Oasis Spa Origin logo'
          />
        </div>

        <div className='columns flex justify-end'>
          <button className='btn hidden' type='button'>
            <span className='bi bi-translate text-xl text-theme-orange'></span>
          </button>

          <button className='btn size-12' type='button' onClick={() => setActive(true)}>
            <span className='bi bi-list text-3xl text-theme-orange'></span>
          </button>
        </div>
      </div>

      <div className={cls('ui--navbar-context', { active })}>
        <div className='head'>
          <div className='columns'>
            <button
              className='btn size-10 rounded-full bg-theme-100/50'
              type='button'
              onClick={() => setActive(false)}>
              <span className='bi bi-x-lg text-xl text-theme-orange'></span>
            </button>
          </div>

          <div className='columns'>
            <Image
              className='mx-auto size-16 object-contain object-center'
              src='/static/images/logo-sm.png'
              width={60}
              height={60}
              quality={100}
              priority
              alt='Oasis Spa Origin logo'
            />
          </div>

          <div className='columns'></div>
        </div>

        <div className='body px-4 pb-4'>
          <div className='rows grid grid-cols-3 gap-4'>
            <Link
              className='router-link btn h-10 rounded bg-theme text-theme-light'
              href='/'
              aria-label='Menu home'
              onClick={() => setActive(false)}>
              <span className='text-sm font-medium uppercase tracking-wider'>home</span>
            </Link>

            <a
              className='router-link btn h-10 rounded bg-theme text-theme-light'
              href='/#'
              aria-label='Menu news & events'
              onClick={() => setActive(false)}>
              <span className='text-sm font-medium uppercase tracking-wider'>news & events</span>
            </a>

            <a
              className='router-link btn h-10 rounded bg-theme text-theme-light'
              href={`https://oasisspa.net/${currentLocale.short}/promotions?utm_source=New Webapp`}
              target='_parent'
              rel='external'
              aria-label='Menu promotions'
              onClick={() => setActive(false)}>
              <span className='text-sm font-medium uppercase tracking-wider'>promotions</span>
            </a>

            <a
              className='router-link btn h-10 rounded bg-theme text-theme-light'
              href={`https://oasisspa.net/${currentLocale.short}/perfectgift/membership?utm_source=New Webapp`}
              target='_parent'
              rel='external'
              aria-label='Menu membership'
              onClick={() => setActive(false)}>
              <span className='text-sm font-medium uppercase tracking-wider'>membership</span>
            </a>

            <a
              className='router-link btn h-10 rounded bg-theme text-theme-light'
              href={`https://oasisspa.net/${currentLocale.short}/promotions?utm_source=New Webapp`}
              target='_parent'
              rel='external'
              aria-label='Menu contact us'
              onClick={() => setActive(false)}>
              <span className='text-sm font-medium uppercase tracking-wider'>contact us</span>
            </a>

            <a
              className='router-link btn h-10 rounded bg-theme text-theme-light'
              href={`https://oasisspa.net/${currentLocale.short}/onlinegift/redeem?utm_source=New Webapp`}
              target='_parent'
              rel='external'
              aria-label='Menu redeem e-voucher'
              onClick={() => setActive(false)}>
              <span className='text-sm font-medium uppercase tracking-wider'>Redeem e-Voucher</span>
            </a>
          </div>

          {menu
            .filter((r) => !/(home|promotion|membership)/.test(r.key))
            .map((record, index) => (
              <div className='rows' key={index}>
                <Col data={record} lang={currentLocale.short} key={record.key} />
              </div>
            ))}
        </div>
      </div>
    </nav>
  )
}

export function Col({ data, lang }: { data: Menu; lang: string }) {
  // __STATE's
  const t = useTranslations()
  const [active, setActive] = useState<boolean>(false)

  // __RENDER
  return (
    <div className='relative'>
      <div
        className='grid h-12 cursor-pointer grid-cols-[auto_1fr_auto] items-center gap-6'
        onClick={() => setActive((prev) => !prev)}>
        <span className={cls('bi bi-circle-fill text-xs', active ? 'text-theme-orange' : 'text-theme-200')} />
        <div className='font-semibold uppercase'>{t(data.label)}</div>
        <span className={cls('bi', active ? 'bi-chevron-down' : 'bi-chevron-right')} />
      </div>

      {active && (
        <div className='grid gap-1'>
          {data.children!.map((record) => (
            <a
              className='router-link bg-theme-200/75 px-4 py-2 text-theme'
              href={`https://oasisspa.net/${lang}${record.href}?utm_source=New Webapp`}
              target='_parent'
              rel='external'
              aria-disabled={record?.disabled}
              aria-label={t(record.label)}>
              <span className='text-sm font-medium uppercase'>{t(record.label)}</span>
            </a>
          ))}
        </div>
      )}
    </div>
  )
}
